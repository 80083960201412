<template>
    <v-container fluid>

        <v-form @submit.prevent="getOrders('search')">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn @click="setBack()"  class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('orders') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="transaction_number" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="transaction_number" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-shopping"
                                                  :label="$t('order_number')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="total" rules="min_value:0"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="total" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-sack"
                                                  :label="$t('order_total')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <v-dialog ref="paymentDateDialogFrom" v-model="paymentDateDialogFrom" width="290px">
                                    <v-date-picker ref="pickerExpirationFrom" v-model="payment_date_from"
                                                   :max="payment_date_to ? $moment(payment_date_to).format('YYYY-MM-DD') : $moment().add(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang"
                                                   @change="paymentDateDialogFrom = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="payment_date_from" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="payment_date_from" :error-messages="errors"
                                                  :disabled="loading" :label="$t('date_from')"
                                                  :error="!valid"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="paymentDateDialogFrom = !paymentDateDialogFrom"
                                                  clearable @click:clear="payment_date_from = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <v-dialog ref="paymentDateDialogTo" v-model="paymentDateDialogTo" width="290px">
                                    <v-date-picker ref="pickerExpirationTo" v-model="payment_date_to"
                                                   :min="payment_date_from ? $moment(payment_date_from).format('YYYY-MM-DD') : $moment().subtract(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="paymentDateDialogTo = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="payment_date_to" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="payment_date_to" :error-messages="errors" :disabled="loading"
                                                  :label="$t('date_to')" prepend-icon="mdi-calendar"
                                                  :error="!valid"
                                                  color="primary" readonly clearable
                                                  @click.stop="paymentDateDialogTo = !paymentDateDialogTo"
                                                  @click:clear="payment_date_to = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="transaction_type" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="transaction_type" :disabled="loadingTransactionType"
                                              :items="transaction_typeItems" :error-messages="errors"
                                              prepend-icon="mdi-home-variant-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('transaction_type')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="transaction_status" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="transaction_status" :disabled="loadingTransactionStatus"
                                              :items="transaction_statusItems" :error-messages="errors"
                                              prepend-icon="mdi-home-variant-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('transaction_status')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>


                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="orderItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalOrders" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                        </div>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}
                    </template>
                    <template v-slot:item.payment_date="{ item }">
                        {{ item.payment_date ? $moment(item.payment_date).format('DD.MM.YYYY HH:mm') : $t('not_paid_for') }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="showOrder(item)" icon>
                                    <v-icon>mdi-card-text-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('more_detailed') }}</span>
                        </v-tooltip>

                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>

        <v-dialog
            v-model="dialog_detail"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card v-if="detail">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog_detail = false">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-toolbar-title class="px-0">{{ $t('order_number_n') }} {{ detail.transaction_number }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog_detail = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mx-1 px-1">
                    <v-container fluid cl>
                        <!-- Payment Details -->
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined class="rounded-lg">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ $t('payment_details') }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ $t('order_amount') }}: {{ detail.total }}</v-list-item-subtitle>
                                                <v-list-item-subtitle>{{ $t('order_date') }}: {{ $moment(detail.created_at).format('DD.MM.YYYY HH:mm') }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="detail.payment_date">
                                                    {{ $t('payment_date') }}: {{ $moment(detail.payment_date).format('DD.MM.YYYY HH:mm') }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>{{ $t('used_bonuses') }}: {{ detail.points }}</v-list-item-subtitle>
                                                <v-list-item-subtitle>{{ $t('money_used') }}: {{ detail.amount }}</v-list-item-subtitle>
                                                <v-list-item-subtitle>{{ $t('transaction_type') }}: {{ detail.transaction_type.name }}</v-list-item-subtitle>
                                                <v-list-item-subtitle>{{ $t('transaction_status') }}: {{ detail.transaction_status.name }}</v-list-item-subtitle>
                                                <v-list-item-subtitle style="color: red">{{ detail.transaction_error }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Customer Data -->
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined class="rounded-lg">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ $t('customer_data') }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ detail.client.name }}</v-list-item-subtitle>
                                                <v-list-item-subtitle>{{ detail.client.phone }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Company Data -->
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined class="rounded-lg">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ $t('company_data') }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ detail.company.name }}</v-list-item-subtitle>
                                                <v-list-item-subtitle>{{ detail.company.description }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Order Data -->
                        <v-row>
                            <v-col cols="12">
                                <h2>{{ $t('order_data') }}</h2>
                            </v-col>
                        </v-row>

                        <!-- Fitness Orders -->
                        <v-row v-if="detail.order_fitness">
                            <v-col
                                v-for="(item, i) in detail.order_fitness"
                                :key="i"
                                :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                            >
                                <v-card outlined class="rounded-lg">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="mb-1">{{ $t('purchase_subscription') }}</v-list-item-title>
                                            <v-list-item-subtitle class="mb-1">{{ item.title }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="mb-1">{{ $t('cost') }}: {{ item.price }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Park Orders -->
                        <v-row v-if="detail.order_parks">
                            <v-col
                                v-for="(item, i) in detail.order_parks"
                                :key="i"
                                :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                            >
                                <v-card outlined class="rounded-lg">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="mb-1">{{ $t('topping_up_your_card') }}: {{ (item.park_card && item.park_card.number) ? item.park_card.number : '' }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Product Orders -->
                        <v-row v-if="detail.order_products">
                            <v-col
                                v-for="(item, i) in detail.order_products"
                                :key="i"
                                :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                            >
                                <v-card outlined class="rounded-lg">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="mb-1">
                                                {{ item.name }}
                                                {{ item.product_size ? $t('size') + item.product_size : '' }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="mb-1">{{ $t('price_per_piece') }}: {{ item.price }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="mb-1">{{ $t('number_of_pieces') }}: {{ item.count }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="mb-1">{{ $t('total') }}: {{ item.cost }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>{{ item.short_description ? item.short_description : '' }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-avatar size="68">
                                            <img v-if="item.photo" :src="item.photo ? item.photo : defaultAvatar">
                                            <v-icon v-else size="70">mdi-image-off-outline</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Hotel Orders -->
                        <v-row v-if="detail.order_hotels">
                            <v-col
                                v-for="(item, i) in detail.order_hotels"
                                :key="i"
                                cols="12"
                            >
                                <v-card outlined class="rounded-lg">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="mb-1">{{ item.title }} {{ item.room_type }}</v-list-item-title>
                                            <v-list-item-subtitle class="mb-1">{{ $t('price_per_day') }}: {{ item.price }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="mb-1">{{ $t('number_of_days') }}: {{ item.days_stay ?? 0 }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="mb-1">{{ $t('total') }}: {{ item.amount ?? 0 }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="mb-1">{{ $t('check_in_date') }}: {{ item.from_date ?? '' }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="mb-1">{{ $t('departure_date') }}: {{ item.to_date ?? '' }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>{{ item.short_description ? item.short_description : '' }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-avatar size="68">
                                            <img v-if="item.photo" :src="item.photo ? item.photo : defaultAvatar">
                                            <v-icon v-else size="70">mdi-image-off-outline</v-icon>
                                        </v-list-item-avatar>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Coffee House Orders -->
                        <v-row v-if="detail.order_coffee_house">
                            <v-col cols="12">
                                <v-card outlined class="rounded-lg">
                                    <v-row class="py-5 px-5">
                                        <v-col cols="12">
                                            <h3>{{ detail.order_coffee_house.food_order_status.name }}</h3>
                                        </v-col>
                                        <v-col
                                            v-for="(item, i) in detail.order_coffee_house.order_food_menus"
                                            :key="'order_food_menus' + i"
                                            cols="12"
                                            sm="6"
                                            class="py-0 my-0"
                                        >
                                            <v-card outlined class="rounded-lg">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="mb-1">{{ item.category }}: {{ item.title }}</v-list-item-title>
                                                        <v-list-item-subtitle class="mb-1">{{ $t('quantity') }}: {{ item.count ?? 0 }}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="mb-1">{{ $t('price') }}: {{ item.price }}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="mb-1">{{ $t('total') }}: {{ item.amount ?? 0 }}</v-list-item-subtitle>
                                                        <v-list-item-subtitle>{{ item.short_description ? item.short_description : '' }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-avatar size="68">
                                                        <img v-if="item.photo" :src="item.photo ? item.photo : defaultAvatar">
                                                        <v-icon v-else size="70">mdi-image-off-outline</v-icon>
                                                    </v-list-item-avatar>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" v-if="detail.order_coffee_house.food_order_status.id > 1">
                                            <v-btn
                                                v-for="(item, i) in foodOrderStatusItems"
                                                :key="'button_' + i"
                                                class="mr-2"
                                                color="primary"
                                                @click="updateFoodOrderStatus(item.id, detail.id)"
                                            >
                                                {{ item.name }}
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card outlined class="rounded-lg">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="mb-1">{{ detail.order_coffee_house.order_food_delivery.name }}</v-list-item-title>
                                                        <v-list-item-subtitle class="mb-1">{{ detail.order_coffee_house.order_food_delivery.address }}</v-list-item-subtitle>
                                                        <v-list-item-subtitle class="mb-1">{{ detail.order_coffee_house.order_food_delivery.comment }}</v-list-item-subtitle>
                                                        <v-col cols="12">
                                                            <v-row style="background-color: #F5F5F5" class="pa-2">
                                                                <v-col cols="12">
                                                                    <yandex-map
                                                                        v-if="coords && showMap"
                                                                        ref="map"
                                                                        :coords="coords"
                                                                        :show-all-markers="showAllMarkers"
                                                                        :zoom="zoom"
                                                                        @map-was-initialized="initHandler"
                                                                        style="height: 300px"
                                                                    >
                                                                        <ymap-marker
                                                                            ref="mapMarker"
                                                                            marker-id="123"
                                                                            marker-type="placemark"
                                                                            :coords="coords"
                                                                        />
                                                                    </yandex-map>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Shopping Center Service -->
                        <v-row v-if="detail.order_shopping_center_service">
                            <v-col cols="12">
                                <v-card outlined class="rounded-lg">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ detail.order_shopping_center_service.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Certificates -->
                        <v-row v-if="detail.client_certificates">
                            <v-col cols="12">
                                <v-card outlined class="rounded-lg">
                                    <v-list>
                                        <v-subheader>{{ $t('certificate_data') }}</v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(item, i) in detail.client_certificates"
                                                        :key="i"
                                                        :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                                                    >
                                                        <v-card outlined class="rounded-lg">
                                                            <v-list-item>
                                                                <v-list-item-content>
                                                                    <v-list-item-title class="mb-1">{{ item.title }}</v-list-item-title>
                                                                    <v-list-item-subtitle>{{ item.short_description ? item.short_description : '' }}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                                <v-list-item-avatar size="68" class="border1_B3B3B3">
                                                                    <img v-if="item.photo" :src="item.photo ? item.photo : defaultAvatar">
                                                                    <v-icon v-else size="40" class="color_B3B3B3">${{ 'settingsIcon' }}</v-icon>
                                                                </v-list-item-avatar>
                                                            </v-list-item>
                                                            <v-card-actions>
                                                                <v-list-item-title v-if="item.used">{{ $t('certificate_was_used') }}</v-list-item-title>
                                                                <v-btn
                                                                    v-if="!item.used"
                                                                    outlined
                                                                    :disabled="loading"
                                                                    :loading="loading"
                                                                    color="indigo"
                                                                    class="rounded-lg but_enter mt-4"
                                                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                    @click="confirmUseCertificate(item)"
                                                                >
                                                                    {{ $t('confirm_use_of_certificate') }}
                                                                </v-btn>
                                                                <v-spacer></v-spacer>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <!-- Cancel Order Button -->
                <v-card-actions v-if="detail.transaction_status.id !== 4" class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="cancelOrder(detail)">
                        {{ $t('cancel_order') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="loading" z-index="10" color="#424242">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import {mask} from "vue-the-mask";
import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'

export default {
    name: "Orders",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        yandexMap,
        ymapMarker
    },
    data() {
        return {
            sortBy: "id",
            sortDir: false,
            loading: false,
            loadingCompanies: false,
            order: null,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalOrders: 0,
            orderItems: [],
            headers: [
                {
                    text: this.$t('order_number'),
                    align: "left",
                    sortable: true,
                    value: "transaction_number"
                },
                {
                    text: this.$t('order_total'),
                    align: "left",
                    sortable: true,
                    value: "total"
                },

                {
                    text: this.$t('transaction_type'),
                    align: "left",
                    sortable: true,
                    value: "transaction_type.name"
                },
                {
                    text: this.$t('transaction_status'),
                    align: "left",
                    sortable: true,
                    value: "transaction_status.name"
                },
                {
                    text: this.$t('payment_date'),
                    align: "left",
                    sortable: true,
                    value: "payment_date"
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                },
                {
                    text: this.$t('more_detailed'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            transaction_number: null,
            transaction_type: null,
            transaction_typeItems: [],
            loadingTransactionType: false,
            transaction_status: null,
            transaction_statusItems: [],
            loadingTransactionStatus: false,
            total: null,
            paymentDateDialogFrom: false,
            payment_date_from: null,
            payment_date_to: null,
            paymentDateDialogTo: false,
            dialog_detail: false,
            detail: null,
            phoneRaw: null,
            sum: null,
            showAllMarkers: false,
            mapMarker: null,
            zoom: 12,
            ymaps: null,
            coordinates: [],
            showMap: false,
            foodOrderStatusItems: [],
        };
    },
    computed: {
        ...mapGetters(['lang', 'phoneMask', 'itemsPerPage', 'perPageItems', 'defaultAvatar', 'company_id']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        coords: {
            get: function () {
                return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
            },
            set: function (val) {
                this.coordinates = (val && val.length === 2) ? val : [51, 71]
            }
        },
        mapSettings() {
            this.$yandex.lang = 'ru_RU'
            return this.$yandex
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getTransactionType()
        this.getTransactionStatus()
        this.ymapInit()
    },
    watch: {
        options: {
            handler() {
                this.getOrders()
            },
            deep: false
        },
    },
    methods: {
        setBack(){
            window.history.back();
        },
        async showOrder(item) {
            let type  = this.$route.params.type;
            if (!type) throw new Error("Параметр 'type' отсутствует в маршруте");
            let params = {};
            this.loading = true
            await this.$http
                .get(`partner/${type}_order/${item.id}`, {
                    params: params,
                })
                .then(res => {
                    this.detail = res.body.data
                    if (this.detail.order_coffee_house && this.detail.order_coffee_house.order_food_delivery && this.detail.order_coffee_house.order_food_delivery.location) {
                        this.coordinates = [this.detail.order_coffee_house.order_food_delivery.location[1], this.detail.order_coffee_house.order_food_delivery.location[0]]
                        this.zoom = 18
                        this.getFoodOrderStatus(this.detail.order_coffee_house.food_order_status)
                    }
                    this.dialog_detail = true;
                })
                .catch(err => {
                    this.detail = {};
                    this.$toastr.error(this.$t('order_receipt_error'))
                })
                .finally(end => {
                    this.loading = false
                });
        },
        async getTransactionType() {
            this.loadingTransactionType = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`partner/transaction_type`, {
                    params: params,
                })
                .then(res => {
                    this.transaction_typeItems = res.body.data
                })
                .catch(err => {
                    this.transaction_typeItems = [];
                })
                .finally(end => {
                    this.loadingTransactionType = false
                });
        },
        async getFoodOrderStatus(food_order_status) {
            this.loading = true
            let params = {};
            params.sort_id = food_order_status.id;
            await this.$http
                .get(`partner/food_order_status`, {
                    params: params,
                })
                .then(res => {
                    this.foodOrderStatusItems = res.body.data
                })
                .catch(err => {
                    this.foodOrderStatusItems = [];
                })
                .finally(end => {
                    this.loading = false
                });
        },
        async getTransactionStatus() {
            this.loadingTransactionStatus = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`partner/transaction_status`, {
                    params: params,
                })
                .then(res => {
                    this.transaction_statusItems = res.body.data
                })
                .catch(err => {
                    this.transaction_statusItems = [];
                })
                .finally(end => {
                    this.loadingTransactionStatus = false
                });
        },
        async getOrders(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.transaction_number) {
                params.transaction_number = this.transaction_number;
            }
            if (this.transaction_type) {
                params.transaction_type = this.transaction_type;
            }
            if (this.transaction_status) {
                params.transaction_status = this.transaction_status;
            }
            if (this.total) {
                params.total = this.total;
            }

            if (this.payment_date_to) {
                params.payment_date_to = this.payment_date_to;
            }
            if (this.payment_date_from) {
                params.payment_date_from = this.payment_date_from;
            }
            if (this.type_company) {
                params.type_company = this.type_company;
            }

            params.coffee_house = this.company_id;
            params.restaurant = this.company_id;

            let type2  = this.$route.params.type;
            if (!type2) throw new Error("Параметр 'type' отсутствует в маршруте");
            await this.$http
                .get(`partner/${type2}_order`, {
                    params: params,
                })
                .then(res => {
                    this.orderItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalOrders = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.orderItems = []
                    this.totalOrders = 0
                    this.$toastr.error(this.$t('failed_to_get_list_orders'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async confirmUseCertificate(item) {
            if (confirm(this.$t('delete_order'))) {
                var _this = this
                //  this.loading = true;
                item.used = true
                // await this.$http
                //     .put(`partner/certificate/${item.id}/confirmU`)
                //     .then(res => {
                //         this.$toastr.success(this.$t('order_has_been_deleted'))
                //         this.getOrders()
                //     })
                //     .catch(err => {
                //         this.$toastr.error(this.$t('order_has_not_been_deleted'))
                //         if (err && err.body && err.body.message) {
                //             for (let prop in err.body.errors) {
                //                 if (hasOwnProperty.call(err.body.errors, prop)) {
                //                     if (_this.$refs[prop]) {
                //                         _this.$refs[prop].setErrors([
                //                             err.body.errors[prop][0]
                //                         ])
                //                     }
                //                 }
                //             }
                //             if (!err.body.errors) {
                //                 this.$toastr.error(err.body.message)
                //             }
                //         }
                //     })
                //     .finally(end => {
                //         this.loading = false
                //     })
            }
        },
        async updateFoodOrderStatus(status_id, transactions_id) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('food_order_status_id', status_id)
            formData.append('transactions_id', transactions_id)

            await this.$http
                .put('partner/update_food_order_status', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('ball_status_has_been_changed'))
                    this.getFoodOrderStatus(res.body.data)
                    this.detail.order_coffee_house.food_order_status.name = res.body.data.name
                })
                .catch(err => {
                    this.$toastr.error(this.$t('not_ball_status_has_been_changed'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async ymapInit() {
            var _this = this
            if (window.ymaps) {
                this.showMap = false
                if (this.$refs.map && this.$refs.map.myMap) {
                    this.$refs.map.myMap.destroy()
                }
                document.getElementById("vue-yandex-maps").remove()
                delete window.ymaps
            }
            await loadYmap({...this.mapSettings, debug: true})
            if (window.ymaps) {
                window.ymaps.ready(function () {
                    _this.showMap = true
                })
            }
        },
        initHandler(e) {
            if (this.coords && this.coords.length !== 2 && window.ymaps) {
                var location = window.ymaps.geolocation.get({
                    provider: 'yandex',
                    mapStateAutoApply: true,
                    autoReverseGeocode: true
                })
                location.then(
                    function (result) {
                        //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                        e.geoObjects.add(result.geoObjects)
                    },
                    function (err) {
                        console.log('Error: ' + err)
                    }
                )
            }
            e.geoObjects.options.set('draggable', true)

        },
        async cancelOrder(item) {
            if (confirm(this.$t('cancel_order_question'))) {
                let type  = this.$route.params.type;
                if (!type) throw new Error("Параметр 'type' отсутствует в маршруте");
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`partner/${type}_order/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('order_has_been_canceled'))
                        this.showOrder(item)
                        this.getOrders()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('order_has_not_been_canceled'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
<style scoped>
.border1_B3B3B3 {
    border: 1px solid #B3B3B3;
}
.color_B3B3B3 {
    color: #B3B3B3;
}
</style>
